function walkMe(isProd) {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');

  if (isProd) {
    script.src = 'walkme/walkme-prod.js';
  } else {
    script.src = 'walkme/walkme-non-prod.js';
  }

  script.type = 'text/javascript';

  head.appendChild(script);
}
